import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import Layout from '../components/Layout'
import styled from 'styled-components'
import OptImages from '../components/OptImages'
import ContactSection from '../components/ContactSection'
import TermsOfUsePageQuery from '../queries/TermsOfUsePageQuery'

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }

  h4 {
    margin: 40px 0 12px;
  }

  li {
    margin: 6px 0 16px;
  }
`

const TermsImg = styled(OptImages.Terms)`
  width: 100%;
  height: 50vh;
  z-index: -1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100vh;
    width: 50vw;
    position: fixed !important;
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <TermsOfUsePageQuery
      render={(content) => (
        <Layout>
          <Box>
            <Grid
              container
              spacing={0}
              justify='center'
              direction='row-reverse'
            >
              <Grid item xs={12} sm={6} container alignItems='center'>
                <Grid item>
                  <StyledBox
                    color='text.primary'
                    m={smUp ? 10 : 3}
                    my={0}
                    py={smUp ? 7 : 12}
                  >
                    <div
                      style={{
                        height: '110px',
                        width: '110px',
                        background: 'white',
                        borderRadius: '100px',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        marginBottom: '10px'
                      }}
                    >
                      <OptImages.EmojiTerms style={{ width: '90px' }} />
                    </div>
                    <h2>Terms of Use</h2>
                    <p>
                      Welcome, And thank you for deciding to register to Brandie
                      and use our products and services. We are happy and
                      grateful for your trust!
                      <br />
                      <br />
                      Brandie Technologies AB, reg. no. 559058-6250,
                      Skeppsbron 8, 111 30 Stockholm, Sweden (hereinafter
                      “Brandie Technologies”, “we”, “our” or “us”) provides an
                      interactive marketing platform called Brandie, which
                      enables individuals to become brand ambassadors. The
                      Service is provided through Brandie Technologies’s mobile
                      application. These Terms of Service (the “Terms”),
                      including the instructions and policies as referred to
                      herein (together hereinafter the “Agreement”) constitutes
                      an agreement between Brandie Technologies and the user of
                      the Service (“User” or “you”). The Agreement regulates the
                      conditions for the User’s access to and use of the
                      Service.
                      <br />
                      <br />
                      By entering into this Agreement in connection with the
                      registration of a Brandie Account, you assure that you
                      have read and approved the Agreement and accept that the
                      Agreement shall govern your rights and obligations in
                      connection with the Service. Furthermore, you agree that
                      you shall comply with the Agreement at all times.
                    </p>
                    <h4>Definitions</h4>
                    <p>For the purposes of these Terms:</p>
                    <ol>
                      <li>
                        “The Brand Owners” means any and all owners of one or
                        more registered trademarks or any other intellectual
                        property, that have entered into an agreement with
                        Brandie Technologies for the purpose of participating
                        and launching campaigns through Brandie.
                      </li>
                      <li>
                        “Brandie Account” means an account that a registered
                        User has created to take part of the Service.
                      </li>
                      <li>
                        “Brands” means any and all registered trademarks, design
                        patents or any other intellectual property, including
                        but not limited to names, logos, brands, text and
                        graphics owned by Brand Owners that are accessible for
                        Users through the Service.
                      </li>
                      <li>
                        “Rewards” include, but is not limited to, gifts,
                        coupons, discounts, bonuses, or similar offers offered
                        by any of the Brand Owners to a User in accordance with
                        the campaign terms accessible exclusively through the
                        Service.
                      </li>
                      <li>
                        “Service” means all features, products and services
                        offered to Users by Brandie Technologies through the
                        interactive marketing platform called Brandie.
                      </li>
                      <li>
                        “Supported Platforms” means any of the Social networks
                        (e.g. Facebook, Instagram, WeChat, Twitter), multimedia
                        websites (e.g. YouTube), and blogs (e.g. Tumblr), and
                        all other web sites the Service is able to share Content
                        to.
                      </li>
                      <li>
                        “User-generated Content” or “Content” means all content
                        generated, uploaded, posted, sent, received, and stored
                        by Users through the Service, which include or may
                        include Brands.
                      </li>
                    </ol>
                    <h4>About the Service</h4>
                    <p>
                      Brandie is the first marketing platform of its kind, it is
                      simply called Crowdmarketing. The main purpose of the
                      Service is to enable the User to become a brand ambassador
                      for any of the Brands, owned by Brand Owners that have
                      entered into an agreement with Brandie Technologies for
                      the purpose of participating and launching campaigns
                      through Brandie. The User chooses which of the Brands he
                      or she wants to represent, selects an appropriate photo in
                      accordance with these Terms, and shares a branded photo
                      post onto any of the Supported Platforms. In return for
                      the loyalty and appreciation, the Brand Owners will reward
                      the User based on how the friends and followers of each
                      User engage with the posted photo on the Supported
                      Platform.
                    </p>
                    <h4>Ownership of the Service</h4>
                    <p>
                      The Service, any content generated by Users using the
                      Service, and the infrastructure used to provide the
                      Service are proprietary to Brandie Technologies.
                    </p>
                    <h4>Rights we grant you</h4>
                    <p>
                      By accessing or using the Service and accepting the
                      Agreement, Brandie Technologies grants you a limited,
                      personal, non-transferable, nonexclusive, revocable
                      license to use the Service pursuant to the Agreement and
                      to any additional terms, policies or amendments set forth
                      by Brandie Technologies.
                    </p>
                    <p>
                      The User do not have the right to copy, modify, reproduce,
                      distribute, create derivative works from, publicly
                      display, publicly perform, license, lease, sell or re-sell
                      any content, software, products or services obtained from
                      or through the Service without the express written
                      permission of Brandie Technologies.
                    </p>
                    <p>
                      You may automatically download and install upgrades,
                      updates or other new features that we provide through the
                      Service. The automatic downloads may be adjustable through
                      your mobile or other IT device.
                    </p>
                    <h4>Registration and Account Security</h4>
                    <p>
                      To be able to use the Service, you must register and
                      create a Brandie Account. Upon registration you shall
                      provide accurate and complete information. As long as you
                      are connected to the Service, you are required to update
                      your information in case it changes.
                    </p>
                    <p>
                      Your Brandie Account is your responsibility and you are
                      solely responsible for any activity that occurs in your
                      Brandie Account. It is therefore important that you
                      protect your Brandie Account information to help Brandie
                      Technologies ensure your Brandie Account security.
                    </p>
                    <p>
                      To be allowed to use the Service, you shall fulfill the
                      requirements a)—k) when registering a Brandie Account and
                      when using the Service:
                    </p>
                    <ol>
                      <li>
                        You shall not create a Brandie Account if you are under
                        the age of 16.
                      </li>
                      <li>
                        You shall not create more than one personal Brandie
                        Account for yourself.
                      </li>
                      <li>
                        Your Brandie Account shall not include any false
                        personal information.
                      </li>
                      <li>
                        If you select a username, that is not your real name, we
                        reserve the right to remove it if deemed appropriate.
                      </li>
                      <li>
                        You shall not create another Brandie Account if Brandie
                        Technologies has already disabled or terminated your
                        Brandie Account, unless you have received Brandie
                        Technologies prior written permission to do so.
                      </li>
                      <li>
                        You shall not buy, sell, rent, or lease access to your
                        Brandie Account, without prior written permission by
                        Brandie Technologies.
                      </li>
                      <li>
                        You will not share your password, let anyone else access
                        your Brandie Account, or do anything else that might
                        jeopardize the security of your Brandie Account.
                      </li>
                      <li>
                        You shall use a strong password and exercise common
                        sense when using the Service to keep your Brandie
                        Account secure.
                      </li>
                      <li>
                        If you suspect that your Brandie Account security has
                        been compromised, you shall immediately contact{' '}
                        <a
                          href='mailto:info@brandie.in?subject=Compromised Account'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {' '}
                          info@brandie.io
                        </a>
                      </li>
                      <li>
                        You shall not violate the Agreement while using the
                        Service.
                      </li>
                      <li>
                        You shall not claim any Rewards without completing the
                        full registration process.
                      </li>
                    </ol>
                    <p>
                      By utilizing the Service and participating in related
                      activities, you certify that you meet the age and other
                      eligibility requirements for the Service set forth in the
                      Agreement. If you do not meet the age and other
                      eligibility requirements, you shall discontinue using the
                      Service immediately.
                    </p>
                    <h4>Privacy</h4>
                    <p>
                      Your privacy is important to us. Our Service is covered by
                      our <a href='/privacy-policy'>Privacy Policy</a>, which
                      describes how we collect, use and disclose your
                      information.
                    </p>
                    <p>
                      By using our Service you confirm that you have received,
                      read and agreed to our entire Privacy Policy, and that you
                      have given your consent to that we may process your
                      personal data. Brandie Technologies is the data controller
                      for the processing of personal data and the processing is
                      done in accordance with the applicable data protection
                      laws, and in particular in accordance with the Swedish
                      Personal Data Act (SFS 1998: 204).
                    </p>
                    <h4>User Conduct</h4>
                    <p>
                      As a User of our Service you accept the role and
                      responsibilities of a brand ambassador for any and all of
                      the Brands. We therefore present you with the conditions
                      a)—n) that you are required to comply with at all times in
                      order to remain a User of the Service and part of the
                      Brandie community:
                    </p>
                    <ol>
                      <li>
                        You shall not violate any provisions of the Agreement,
                        or engage in anything unlawful, misleading, or
                        malicious.
                      </li>
                      <li>
                        You shall not post Content that contains pornography or
                        nudity, graphic violence, threats, hate speech,
                        incitements to violence, or discrimination based on
                        gender, sexual orientation, ethnicity, or religion.
                      </li>
                      <li>
                        You shall not post Content that is alcohol, tobacco or
                        drug related or contains any other intoxicating
                        substances.
                      </li>
                      <li>
                        You shall not post Content that contains or is related
                        to medical products, including but not limited to
                        pharmaceuticals.
                      </li>
                      <li>
                        You shall not post Content with the intent of damaging,
                        shaming, discrediting, or in other ways tarnish the
                        Brands or Brand Owners.
                      </li>
                      <li>
                        You shall not post Content that contain other trademarks
                        than the Brands of each specific campaign.
                      </li>
                      <li>
                        You shall not bully, intimidate, or harass any User.
                      </li>
                      <li>
                        You shall not post unauthorized commercial
                        communications, including but not limited to spam.
                      </li>
                      <li>
                        You shall not collect Users’ Content or information, or
                        otherwise access the Service, using automated means
                        (such as harvesting bots, robots, spiders, or scrapers),
                        upload viruses or other malicious code.
                      </li>
                      <li>
                        You shall not manipulate the interaction rates of your
                        Content using automated means (such as harvesting bots,
                        robots, spiders, or scrapers).
                      </li>
                      <li>
                        You shall not attempt to circumvent any branding
                        techniques we employ, or attempt to access areas or
                        features of the Service that you are not authorized to
                        access.
                      </li>
                      <li>
                        You shall not use the Service in a way that could
                        interfere with, disrupt, negatively affect, or inhibit
                        other Users from fully enjoying the Service, or that
                        could damage, disable, overburden, or impair the
                        functioning of the Service.
                      </li>
                      <li>
                        You shall not promote any of the Brands towards
                        individuals under the age of 16.
                      </li>
                      <li>
                        You shall not encourage or promote any activity that
                        violates any of the provisions of the Agreement.
                      </li>
                    </ol>
                    <p>
                      You alone remain solely responsible for the Content you
                      generate, upload, post, send, or store when using the
                      Service. By using the Service, you shall post Content in
                      accordance with applicable laws and regulations, including
                      but not limited to the Swedish Marketing Practices Act
                      (SFS 2008:486). We reserve the right to immediately
                      terminate or suspend any Brandie Accounts where activity
                      that violates the Agreement has been identified.
                    </p>
                    <p>
                      If you wish to report a violation of these Terms, please
                      forward all evidence of abuse to info@brandie.io. Please
                      refer responsibly.
                    </p>
                    <h4>User-Generated Content</h4>
                    <p>
                      The Service allows you to generate, upload, post, send,
                      receive, and store User-generated Content. When you do so,
                      you retain the ownership rights in that content you had to
                      begin with.
                    </p>
                    <p>
                      If you allow any of the Brand Owners to publish your
                      Content or information, on Brandie or on the Supported
                      Platforms through the Service, it means that you allow
                      everyone who interact with your published Content on
                      Brandie or on the Supported Platforms, to access and use
                      that information, and to associate it with you (i.e., your
                      username and profile picture).
                    </p>
                    <p>
                      When you delete your User-generated Content, it is removed
                      from the Service but may remain on the Supported
                      Platforms. You agree and understand that 1) to remove your
                      User-generated Content you may have to remove it from the
                      Supported Platforms, and 2) backup copies may remain for a
                      reasonable period of time (but will not be available to
                      others).
                    </p>
                    <h4>Rights You Grant Us</h4>
                    <p>
                      By accepting the Agreement you grant Brandie Technologies
                      and the Brand Owners a license to use your User-generated
                      Content.
                    </p>
                    <p>
                      You grant Brandie Technologies a worldwide, royalty-free,
                      sub-licensable, and transferable license to host, store,
                      use, display, reproduce, modify, adapt, edit, publish, and
                      distribute your User-generated Content.
                    </p>
                    <p>
                      With your explicit consent upon a request from any of the
                      Brand Owners made through the Service you grant the
                      requesting Brand Owner a worldwide, royalty-free,
                      sub-licensable, and transferable license to host, store,
                      use, display, reproduce, modify, adapt, edit, publish, and
                      distribute that User-generated Content. In return, the
                      Brand Owners may choose to, but are not obliged to, offer
                      you additional Rewards or benefits from using your
                      User-generated Content when using the Service.
                    </p>
                    <p>
                      The Content shared to any Supported Platforms through the
                      Service shall be subject to the specific terms and
                      conditions provided by each individual social network or
                      site. By using the Service you agree to act in accordance
                      with the terms and conditions that apply for any of the
                      Supported Platforms you share Content to through the
                      Service.
                    </p>
                    <p>
                      If deemed necessary Brandie Technologies may access,
                      review, screen, and delete Content at any time and for any
                      reason, but most importantly, if the Content violates any
                      of the provisions of the Agreement.
                    </p>
                    <p>
                      You are welcome to share your feedback, ideas, and/or
                      suggestions. However, if they are implemented, we are not
                      obliged to compensate you.
                    </p>
                    <h4>Rewards</h4>
                    <p>
                      As a User you may participate in campaigns and generate
                      Content and by doing so become eligible for Rewards
                      offered by the Brand Owners. Prior to participating in a
                      campaign you will be presented with its specific terms
                      that may include: what the Reward is, the total number of
                      Rewards in the campaign, the duration of the campaign,
                      location, the target interactions for a post, and how to
                      claim the Reward including, but not limited to, physical
                      stores, online, and in-app purchases.
                    </p>
                    <p>
                      To become eligible for a Reward you agree to a)—d) below:
                    </p>
                    <ol>
                      <li>
                        You have voluntarily agreed to participate in the
                        campaign.
                      </li>
                      <li>
                        You shall generate Content, which may include, but is
                        not limited to, names, logos, trademarks, text and
                        graphics provided by the Brand Owners through the
                        Service, in accordance with the Agreement.
                      </li>
                      <li>
                        You shall generate Content, that may include, but is not
                        limited to, names, logos, trademarks, text and graphics
                        provided by the Brand Owners, in accordance with terms
                        and conditions for each Supported Platform you share the
                        Content to.
                      </li>
                      <li>
                        You agree and understand that a campaign may only have a
                        limited number of Rewards.
                      </li>
                    </ol>
                    <p>
                      We reserve the right to deny you the opportunity to claim
                      a Reward in case you violate any of the provisions or in
                      any other way misrepresent the Agreement.
                    </p>
                    <h4>Purchases and Payment</h4>
                    <p>
                      If you make a purchase with a Reward from Brandie, the
                      terms of purchase and payments terms provided by the Brand
                      Owners or the relevant outlet shall apply, unless it is
                      explicitly stated otherwise.
                    </p>
                    <p>
                      Brandie Technologies will take no responsibility for the
                      products and/or services that may be redeemed through or
                      vouchers or reward codes. This responsibility is solely
                      between the User and the Brand Owners or partners. In
                      addition to this, Brandie Technologies will make no
                      warranty to the Users for the safety, usability, quality,
                      or any other aspect of the service/product for which the
                      voucher or reward code may be redeemed for. Furthermore,
                      for any services or activities that our vouchers can be
                      redeemed for that could result in or involve bodily harm
                      Brandie Technologies takes no responsibility for the
                      activity or service provided by the Brand Owners or
                      partners. The User will take full responsibility for her
                      or his own actions in utilizing the services that can be
                      redeemed through a Brandie voucher or reward
                    </p>
                    <h4>Refund and Cancellation Policy</h4>
                    <p>
                      All offers, rewards and vouchers in our platform are
                      non-refundable by it’s nature if not specifically
                      mentioned otherwise in the detailed description. If you as
                      a User believes that the product or service redeemed
                      though one of our vouchers didn’t meet your expectation or
                      if you believe you have been victim of fraud or other
                      dissatisfaction, please contact us through any of our
                      channels and we will do our best to assist you and take
                      action accordingly. We may in some cases make exceptions
                      to the non-refund policy.
                    </p>
                    <h4>Mobile Devices and Data Charges</h4>
                    <p>
                      Brandie provides you the Service for free but you are
                      solely responsible for any mobile charges that you may
                      incur for using the Service, including data charges. If
                      you’re unsure what those charges may be, you should
                      consult your service provider before using the Service.
                    </p>
                    <p>
                      When using the Service, you provide Brandie Technologies
                      with your consent and all rights necessary to enable Users
                      to sync their devices with any information that is visible
                      to them through Brandie.
                    </p>
                    <h4>
                      Copyright, Trademarks and Other Intellectual Property
                    </h4>
                    <p>
                      The Brands provided by the Brand Owners through the
                      Service are protected by intellectual property law and are
                      under license from the Brand Owners to Brandie
                      Technologies. The Brands shall therefore be used by you
                      for the sole purpose of generating and sharing Content in
                      accordance with the Agreement.
                    </p>
                    <p>
                      The Service contains copyrighted material, trademarks,
                      design patents and other proprietary information,
                      including, without limitation, text, software, photos,
                      video, and graphics. The entire contents of the Service
                      are protected by copyright, trademarks, design patents and
                      other intellectual property owned by Brandie Technologies,
                      the Brand Owners or other third parties. Brandie
                      Technologies owns a copyright in the selection,
                      coordination, arrangement and enhancement of such content,
                      as well as in the content original to it. You may not
                      modify, distribute, publish, transmit, publicly display,
                      publicly perform, participate in the transfer or sale,
                      create derivative works or in any way exploit any of the
                      content or User-generated Content, in whole or in part. No
                      downloading, copying, redistribution, retransmission,
                      publication or commercial exploitation of the Service, the
                      content or the User-generated Content is permitted without
                      the express permission of Brandie Technologies. If
                      downloading, copying, redistribution, retransmission or
                      publication of copyrighted material is permitted by us,
                      you shall make independent attribution and/or make no
                      changes in or deletion of any author attribution,
                      trademark legend or copyright notice. You acknowledge that
                      you do not acquire any ownership rights, including but not
                      limited to intellectual property rights, by downloading or
                      using material in connection with the Service. Any
                      violation of these restrictions may result in a copyright,
                      trademark, design patent or other intellectual property
                      right infringement that may subject you to civil and/or
                      criminal penalties.
                    </p>
                    <h4>Term and Termination</h4>
                    <p>
                      The Agreement is valid until further notice. You may
                      terminate the Agreement and, consequently, delete your
                      Brandie Account or disable your application at any time.
                      In all such cases, this Agreement no longer applies, with
                      the exception of section: 5, 7-9, 14-21 and 23. Brandie
                      Technologies may terminate the Agreement and/or cease to
                      provide the Service at any time.
                    </p>
                    <p>
                      Brandie Technologies reserves the right to delete your
                      Brandie Account and deny you access to the Service and/or
                      terminate the Agreement if:
                    </p>
                    <ol>
                      <li>
                        You violate any of the provisions of the Agreement or if
                        Brandie Technologies deems that you in any way create
                        risk or possible legal exposure for Brandie
                        Technologies, the Brand Owners, and/or the Supported
                        Platforms,
                      </li>
                      <li>
                        Brandie Technologies has received information about, or
                        has reasonable grounds to assume that you will use the
                        Service in violation of this Agreement or any applicable
                        law or regulation.
                      </li>
                      <li>
                        There is any information regarding technical or
                        administrative routines for information security
                        indicating that there are reasonable grounds to believe
                        that your Brandie Account will be misused.
                      </li>
                      <li>
                        There is any other reason to believe that your Brandie
                        Account has been misused or will be misused,
                      </li>
                      <li>
                        You revoke your consent to the processing of personal
                        data as described in item 6.2, or
                      </li>
                      <li>
                        You do not provide accurate, complete and updated
                        information in connection with your Brandie Account.
                      </li>
                      <p>
                        You will be notified of such termination, deletion or
                        denied access by email or the next time you attempt to
                        access your Brandie Account.
                      </p>
                    </ol>
                    <h4>Governing Law and Disputes</h4>
                    <p>
                      The Agreement shall be governed by and interpreted in
                      accordance with the national laws and provisions of the
                      country of Sweden. You hereby consent to the exclusive
                      jurisdiction of and venue in Swedish courts, in any
                      dispute, controversy or claim arising out of or in
                      connection with the Agreement, or the breach, termination
                      or invalidity thereof.
                    </p>
                    <p>
                      Notwithstanding any other provisions of the Agreement,
                      Brandie Technologies may seek injunctive or other
                      equitable relief from any court of competent jurisdiction.
                      You agree that any dispute that cannot be resolved between
                      the parties shall be resolved individually, without resort
                      to any form of class action.
                    </p>
                    <p>
                      If you are not pleased with or have questions concerning
                      our Service, you are welcome to contact us at any time.
                    </p>
                    <h4>Disclaimer</h4>
                    <p>
                      WHILE BRANDIE TECHNOLOGIES USES REASONABLE EFFORTS TO
                      INCLUDE ACCURATE AND UP-TO-DATE INFORMATION, BRANDIE
                      TECHNOLOGIES SPECIFICALLY DISCLAIMS ANY AND ALL
                      RESPONSIBILITY OR LIABILITY FOR THE ACCURACY, CONTENT,
                      COMPLETENESS, LEGALITY, RELIABILITY, OR OPERABILITY OR
                      AVAILABILITY OF INFORMATION OR MATERIAL DISPLAYED IN ANY
                      AND ALL OF BRANDIE TECHNOLOGIES’S WEB SITES, EITHER NOW
                      OPERATING OR CREATED IN THE FUTURE. BRANDIE TECHNOLOGIES
                      DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR THE
                      DELETION, FAILURE TO STORE, MIS-DELIVERY, OR UNTIMELY
                      DELIVERY OF ANY INFORMATION OR MATERIAL. BRANDIE
                      TECHNOLOGIES DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR
                      ANY HARM RESULTING FROM DOWNLOADING OR ACCESSING ANY
                      INFORMATION OR MATERIAL ON THE INTERNET THROUGH THE
                      SERVICE. WITHOUT LIMITING THE FOREGOING, UNDER NO
                      CIRCUMSTANCES SHALL BRANDIE TECHNOLOGIES BE HELD LIABLE
                      FOR ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY
                      OR INDIRECTLY FROM ACTS OF NATURE, FORCES, OR CAUSES
                      BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT
                      LIMITATION, INTERNET FAILURES, COMPUTER EQUIPMENT
                      FAILURES, TELECOMMUNICATION EQUIPMENT FAILURES, OTHER
                      EQUIPMENT FAILURES, ELECTRICAL POWER FAILURES, STRIKES,
                      LABOR DISPUTES, RIOTS, INSURRECTIONS, CIVIL DISTURBANCES,
                      SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS, STORMS,
                      EXPLOSIONS, ACTS OF GOD, WAR, GOVERNMENTAL ACTIONS, ORDERS
                      OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS,
                      NON-PERFORMANCE OF THIRD PARTIES, OR LOSS OF OR
                      FLUCTUATIONS IN HEAT, LIGHT, OR AIR CONDITIONING.
                    </p>
                    <p>
                      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE
                      IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                      BRANDIE TECHNOLOGIES EXPRESSLY DISCLAIMS ALL WARRANTIES OF
                      ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                      LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                      BRANDIE MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET
                      YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED,
                      TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY
                      BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE
                      OR RELIABLE, (IV) THE QUALITY OF ANY PRODUCTS, SERVICES OR
                      INFORMATION PURCHASED OR OBTAINED BY YOU THROUGH THE
                      SERVICE WILL MEET YOUR EXPECTATIONS, (V) YOUR MESSAGES,
                      DATA OR INFORMATION, IN WHATEVER FORM OR MEDIUM, WILL NOT
                      BE LOST, AND (VI) ANY ERRORS WILL BE CORRECTED. ANY
                      MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                      SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK, AND YOU
                      WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
                      SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF
                      ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL
                      OR WRITTEN, OBTAINED BY YOU FROM BRANDIE TECHNOLOGIES, OR
                      THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT
                      EXPRESSLY STATED IN THESE TERMS.
                    </p>
                    <p>
                      YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE
                      OBTAIN MATERIAL OR DATA THROUGH THE USE OF WEB SITES
                      PROVIDED BY BRANDIE TECHNOLOGIES AT YOUR OWN DISCRETION
                      AND RISK. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM
                      JURISDICTION TO JURISDICTION. BRANDIE TECHNOLOGIES AND ITS
                      WEB SITES ARE NOT RESPONSIBLE OR LIABLE FOR CONTENT POSTED
                      BY USERS, THIRD PARTIES, ACTIONS OF ANY THIRD PARTY OR FOR
                      ANY DAMAGE TO, OR VIRUS THAT MAY INFECT, A USER’S COMPUTER
                      EQUIPMENT OR OTHER PROPERTY.
                    </p>
                    <h4>Limitation of Liability</h4>
                    <p>
                      YOU AGREE THAT BRANDIE TECHNOLOGIES AND ITS AFFILIATES
                      WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
                      INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                      INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
                      GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
                      BRANDIE TECHNOLOGIES AND ITS AFFILIATES HAVE BEEN ADVISED
                      OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I)
                      THE USE OR INABILITY TO USE THE SERVICE; (II) THE COST OF
                      GETTING SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
                      PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR
                      OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
                      THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO
                      OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV)
                      STATEMENTS OR CONDUCT OF ANYONE IN CONNECTION WITH THE
                      SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE.
                      IF, NOTWITHSTANDING THE OTHER PROVISIONS OF THESE TERMS,
                      BRANDIE TECHNOLOGIES AND ITS AFFILIATES ARE FOUND TO BE
                      LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH ARISE OUT OF OR
                      IS ANY WAY CONNECTED TO YOUR USE OF THE SERVICES,
                      LIABILITY OF BRANDIE TECHNOLOGIES AND ITS AFFILIATES SHALL
                      IN NO EVENT AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO
                      THE SERVICES EXCEED THE GREATER OF 1,000 SEK OR THE AMOUNT
                      YOU PAID BRANDIE TECHNOLOGIES, IF ANY, IN THE LAST 12
                      MONTHS.
                    </p>
                    <h4>Indemnification</h4>
                    <p>
                      BY REGISTERING AND/OR PARTICIPATING IN ANY SERVICES
                      OFFERED BY BRANDIE TECHNOLOGIES, YOU AGREE TO INDEMNIFY,
                      DEFEND, AND HOLD HARMLESS BRANDIE TECHNOLOGIES, ITS
                      SUBSIDIARIES, AFFILIATES, AND THE DIRECTORS, OFFICERS,
                      EMPLOYEES, SHAREHOLDERS, VENDORS, PARTNERS, CONTRACTORS,
                      AGENTS, LICENSORS OR OTHER REPRESENTATIVES OF EACH OF THEM
                      AND ALL THEIR SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE
                      “INDEMNITEES”) IN RESPECT OF ALL CLAIMS, COSTS (INCLUDING
                      LEGAL FEES AND COSTS), DAMAGES, LIABILITIES AND EXPENSES
                      OR OBLIGATIONS OF ANY KIND, ARISING OUT OF OR IN
                      CONNECTION WITH YOUR USE OR MISUSE OF THE SERVICE
                      (INCLUDING WITHOUT LIMITATION USE OF YOUR BRANDIE ACCOUNT,
                      WHETHER OR NOT AUTHORIZED BY YOU). BRANDIE RETAINS THE
                      RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY
                      CLAIM SUPPORTING INDEMNIFICATION, AND IN SUCH CASES YOU
                      AGREE TO COOPERATE WITH US TO DEFEND ANY SUCH CLAIM. YOU
                      WILL NOT SETTLE ANY CLAIM COVERED BY THIS SECTION AND THE
                      DISCLAIMER OF WARRANTIES WITHOUT BRANDIE TECHNOLOGIES’S
                      PRIOR WRITTEN APPROVAL.
                    </p>
                    <p>
                      BY REGISTERING FOR A BRANDIE ACCOUNT AND/OR ACTIVITY
                      RELATED TO USING THE SERVICE, YOU AGREE WITHOUT LIMITATION
                      THAT BRANDIE TECHNOLOGIES IS NOT RESPONSIBLE FOR ANY
                      DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING, TAMPERING,
                      OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR
                      BRANDIE ACCOUNT. ANY ATTEMPT TO GAIN UNAUTHORIZED ACCESS
                      TO THE BRANDIE TECHNOLOGIES’S SYSTEMS, THE SERVICE OR ANY
                      BRANDIE ACCOUNT, INTERFERENCE WITH PROCEDURES OR
                      PERFORMANCE OF THE SERVICE, OR DELIBERATELY DAMAGING OR
                      UNDERMINING THE SERVICE IS SUBJECT TO CIVIL AND/OR
                      CRIMINAL PROSECUTION AND WILL RESULT IN IMMEDIATE
                      TERMINATION OF YOUR BRANDIE ACCOUNT AND FORFEITURE OF ANY
                      REWARDS TO WHICH YOU ARE OTHERWISE ENTITLED. ANY ATTEMPT
                      TO USE THE SERVICE BY MEANS OF AUTOMATIC, MACRO,
                      PROGRAMMED, OR SIMILAR METHODS, OR TO OTHERWISE COMMIT
                      FRAUD WITH REGARD TO THE SERVICE, WILL RESULT IN ACTIVE
                      PURSUIT OF CIVIL LITIGATION AND/OR CRIMINAL PROSECUTION,
                      TERMINATION OF YOUR BRANDIE ACCOUNT, AND FORFEITURE OF ALL
                      PRIZES TO WHICH YOU MIGHT OTHERWISE BE ENTITLED.
                    </p>
                    <h4>Web Sites or Services Provided by Third Parties</h4>
                    <p>
                      The Service may contain links or references to web sites
                      that are not operated by Brandie Technologies. Brandie
                      Technologies does not have any control over, and accepts
                      no responsibility for, the content of such web sites. The
                      User is fully responsible for the use of such web sites
                      and is therefore recommended to carefully read any user
                      terms and policies regarding privacy and confidentiality
                      that may be applicable.
                    </p>
                    <h4>Transfer of Rights</h4>
                    <p>
                      The rights and obligations of this Agreement may not be
                      transferred by a User without Brandie Technologies prior
                      written consent. Brandie Technologies has the right to
                      fully or partially transfer rights and obligations under
                      this Agreement to another party without the User’s
                      consent.
                    </p>
                    <h4>
                      Special Provisions Applicable to Users Outside Sweden
                    </h4>
                    <p>
                      Brandie Technologies’s ambition is to create a global
                      community where the Agreement apply to all users. Brandie
                      Technologies strives to respect local laws. If you are a
                      User or non-user who interact with Brandie Technologies’s
                      application and/or Service outside Sweden you consent to
                      having your personal data transferred to and processed in
                      Sweden.
                    </p>
                    <p>
                      By using the Service, you shall post Content in accordance
                      with applicable laws and regulations, including but not
                      limited to marketing laws.
                    </p>
                    <p>
                      Brandie Technologies makes no representation that the
                      Service is operated in accordance with the laws or
                      regulations of, or governed by, other nations.
                    </p>
                    <h4>Modification and Amendments</h4>
                    <p>
                      Brandie Technologies will send you a notification at least
                      one (1) month prior to making changes to these Terms and
                      will give you the opportunity to unsubscribe from our
                      Service and uninstall the application, if you do not agree
                      with such changes.
                    </p>
                    <p>
                      Your continued use of our Service, following notice of the
                      changes to the Terms, constitutes your acceptance of any
                      amendments or modifications.
                    </p>
                    <p>
                      Brandie Technologies is consistently trying to improve and
                      grow the Service for you. At times we may add or remove
                      features, products, or functionalities, and we may also
                      suspend or stop the Service altogether. We may take any of
                      these actions at any time, without providing you with any
                      prior written notice.
                    </p>
                    <h4>Miscellaneous</h4>
                    <p>
                      Should the Agreement contain any provision which is
                      ineffective, this shall not affect the validity of the
                      remaining provisions of the Agreement.
                    </p>
                    <p>
                      No failure or delay in exercising any right, power or
                      privilege hereunder shall operate as a waiver thereof, nor
                      shall single or partial exercise thereof preclude any
                      other or further exercise thereof or the exercise of any
                      other right, power or privilege hereunder.
                    </p>
                    <p>
                      In case a translated version of the Agreement conflict
                      with the English version, the English version of the
                      Agreement shall prevail.
                    </p>
                    <p>
                      We sincerely hope you will enjoy the Service and encourage
                      you to reach out to us with any ideas or questions! We
                      look forward to hearing from you!
                    </p>
                    <p>/ The Brandie Team</p>
                  </StyledBox>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <TermsImg />
              </Grid>
            </Grid>
          </Box>

          <ContactSection />
        </Layout>
      )}
    />
  )
}
